/*
 * @Author: 刘彭涛 157511383@qq.com
 * @Date: 2022-04-09 13:57:38
 * @LastEditors: 刘彭涛 157511383@qq.com
 * @LastEditTime: 2022-08-30 11:32:31
 * @FilePath: \zero-c-cloud\src\utils\aioxs.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import axios from 'axios'
import router from '../router/index'
import { ElMessage, ElNotification } from 'element-plus'
import { filterObject, domainid, uuid } from '@/utils/utils'
import { showFullScreenLoading, HideFullScreenLoading } from './loadding'
import store from '../store/index'
import { format } from 'date-fns'
import useStorage from '@/hooks/storage'

const { getStorage, setStorage } = useStorage()

const service = axios.create({
  baseURL: process.env.VUE_APP_SERVICE_URL, // url = base url + request url
  headers: {
    'Content-Type': 'application/json',
    Timezone: (new Date().getTimezoneOffset() / 60) * -1,
  },
  withCredentials: true,
})
let env = process.env.NODE_ENV
// 1.axios拦截器
service.interceptors.request.use(
  config => {
    try {
      if (config.data && config.data.param) {
        config.data.param = filterObject(config.data.param)
      }
    } catch (err) {
      console.log(err)
    }

    if (config.url[0] !== '/') config.url = '/' + config.url
    config.url = '/d/' + window.location.pathname.split('/')[1] + config.url
    if (config.params && config.params.showLoding) {
      showFullScreenLoading()
    } else {
      HideFullScreenLoading()
    }
    if (config.params && config.params.showLoding) {
      delete config.params.showLoding
    }

    if (config.requestBaseUrl === 'restapi') {
      config.baseURL = 'https://restapi.amap.com'
    }
    if (store && store.getters.getParallelSpace.open) {
      config.headers['Time-Engine'] = 1
    } else {
      config.headers['Time-Engine'] = 0
    }
    return config
  },
  err => {
    return Promise.reject(err)
  },
)
// 1.axios响应拦截
service.interceptors.response.use(
  response => {
    HideFullScreenLoading()
    return response
  },
  error => {
    HideFullScreenLoading()
    let code = error.response.status
    switch (code) {
      case 401 || 503:
        let domainId = domainid()
        let query = {}
        if (domainId === 'DKseGL0xtdHO61iFCoz') {
          query.redirect = router.currentRoute.value.path
          query.domainId = domainId
          query.autologin = true
        }

        if (getStorage('autoLoginConfig', 'session')) {
          store.dispatch('autoLogin')
          return
        }

        store.dispatch('clearState')
        ElMessage.error('登录超时，请重新登录')
        store.dispatch('setShowRelogin', true)
        // router.replace({
        //   path: '/login',
        //   query,
        // })
        return error.response
        break
      case 403:
        ElNotification({
          message: error.response.data.msg || error.message,
          type: 'error',
        })

        // ElMessage.error(error.response.data.msg || error.message)
        return error.response
        break
      default:
        if (env === 'development') {
          ElNotification({
            message: '请求异常',
            type: 'error',
          })
        }
        store.dispatch('setResponseError', {
          time: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
          routePath: router.currentRoute.value.path,
          routeName: router.currentRoute.value.meta._meauname,
          requestUrl: error.response.config.url,
          request_id: error.response.headers['request_id'],
          requestData: error.response.config.data,
          error: JSON.stringify(error.response.data),
          isViewed: false,
          key: uuid(),
        })
        // ElMessage.error('请求异常')
        return Promise.reject(error.response.data)
        break
    }
  },
)
export default service
