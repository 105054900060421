import { createStore } from 'vuex'
import SocketPlugin from '@/server/socket/webSoket'
import { useRoute } from 'vue-router'
import router, { routerList } from '../router'
import API from '@/server/index.js'
import { getMenuList, AddRoute } from './config.js'
import { childRouter } from 'router/childRouter'
import { typeOf, deepCopy } from '@/utils/utils'
import useStorage from '@/hooks/storage'
import { getConfigApi } from '@/server/config.js'
import { ElMessage } from 'element-plus'
import zh from '../language/locales/zh'
import en from '../language/locales/en'
import th from '../language/locales/th'

const { removeStorage, clearStorage, getStorage, setStorage } = useStorage()

export default createStore({
  state: {
    activeValue: [],
    activeIndex: {},
    activeSiteId: '',
    socket: null,
    keepLive: [],
    SiteInformation: {}, //站点信息
    MenuList: [],
    activePath: '',
    modeData: {}, //物模型加载的数据
    modeErrorAlarm: [], //无模型输入以后的告警信息
    parallelSpace: {
      key: '',
      open: false,
      type: '',
      rate: 1,
      dates: [],
      system_dates: [],
    },
    currentTime: [],
    userRole: [],
    userInfo: {},
    responseErrList: [],
    functionDoc: null,
    operatePermissions: {},
    showRelogin: false,
    languageData: {}, // 多语言包
    checkRulesParams: {}, // 校验规则参数存储
  },
  getters: {
    getActive(state) {
      return state.activeValue
    },
    getActiveIndex(state) {
      return state.activeIndex
    },
    getActiveSiteId(state) {
      return state.activeSiteId
    },
    getSocket(state) {
      return new Promise((resolve, inject) => {
        let timeout = setInterval(() => {
          if (state.socket) {
            resolve(state.socket)
            clearInterval(timeout)
          }
        }, 100)
      })
    },
    getkeepLive(state) {
      return state.keepLive
    },
    getSiteInformation(state) {
      //获取站点信息
      return state.SiteInformation
    },
    getMenu(state) {
      return state.MenuList
    },
    getActivePath(state) {
      return state.activePath
    },
    getModeData(state) {
      return state.modeData
    },
    getModeError(state) {
      return state.modeErrorAlarm
    },
    getParallelSpace(state) {
      return state.parallelSpace
    },
    getParallelSpaceKey(state) {
      return state.parallelSpace.key
    },
    getUserRole(state) {
      return state.userRole
    },
    getUserInfo(state) {
      return state.userInfo
    },
    getResponseError(state) {
      return state.responseErrList
    },
    getFunctionDoc(state) {
      return state.functionDoc
    },
    getOperatePermissions(state) {
      return state.operatePermissions
    },
    getShowRelogin(state) {
      return state.showRelogin
    },
  },
  mutations: {
    setActive(state, info) {
      let status = state.activeValue.some(item => {
        return item.path == info.path || item.name == info.name
      })
      if (!status) state.activeValue.push(info)
    },
    removeActive(state, info) {
      let newActive = state.activeValue.filter(item => {
        return item.name != info
      })
      state.activeValue = newActive
    },
    onlySaveCurrent(state, info) {
      let newActive = state.activeValue.filter(item => {
        return item.name == info
      })
      state.activeValue = newActive
    },
    setActiveIndex(state, info) {
      state.activeIndex = info
    },
    setActiveSiteId(state, info) {
      state.activeSiteId = info
    },
    removeAllActive(state) {
      state.activeValue = []
    },
    setSocket(state, info) {
      state.socket = info
    },
    setkeepLive(state, info) {
      if (!state.keepLive.includes(info)) {
        state.keepLive.push(info)
      }
    },
    delKeepLive(state, info) {
      if (state.keepLive.includes(info)) {
        let index = state.keepLive.indexOf(info)
        state.keepLive.splice(index, 1)
      }
    },

    checkRulesStoreParams(state, info) {
      state.checkRulesParams = info
    },

    setSiteInformation(state, info) {
      //设置站点信息
      state.SiteInformation = info
    },
    clearState(state) {
      state.activeValue = []
      state.activeSiteId = ''
      state.keepLive = []
      state.SiteInformation = {}
      state.MenuList = []
      state.responseErrList = []
      if (state.socket) {
        state.socket.close()
      }
      clearStorage('session')
      removeStorage('userInfo')
      removeStorage('ationsInfo')
      removeStorage('themeuseInfo')
    },
    setMenu(state, info) {
      state.MenuList = info
    },
    setActivepath(state, info) {
      state.activePath = info
    },
    setModeData(state, info) {
      state.modeData = info
    },
    setModeError(state, info) {
      state.modeErrorAlarm = info
    },
    setParallelSpace(state, info) {
      state.parallelSpace = {
        ...state.parallelSpace,
        ...info,
      }
    },
    setUserRole(state, info) {
      state.userRole = info
    },
    setUserInfo(state, info) {
      state.userInfo = info
    },
    setResponseError(state, info) {
      state.responseErrList = info
    },
    setFunctionDoc(state, info) {
      return (state.functionDoc = info)
    },
    setOperatePermissions(state, info) {
      state.operatePermissions = info
    },
    setShowRelogin(state, info) {
      state.showRelogin = info
    },
    setLanguage(state, info) {
      state.languageData = info
    },
  },
  actions: {
    setActive(context, data) {
      context.commit('setActive', data)
    },
    removeActive(context, data) {
      context.commit('removeActive', data)
    },
    onlySaveCurrent(context, data) {
      context.commit('onlySaveCurrent', data)
    },
    setActiveIndex(context, data) {
      context.commit('setActiveIndex', data)
    },
    setActiveSiteId(context, data) {
      context.commit('setActiveSiteId', data)
    },
    removeAllActive(context) {
      context.commit('removeAllActive')
    },
    setkeepLive(context, data) {
      context.commit('setkeepLive', data)
    },
    delKeepLive(context, data) {
      context.commit('delKeepLive', data)
    },
    async createSocket(context, data) {
      let socket = null
      socket = new SocketPlugin()
      await socket.connect()
      context.commit('setSocket', socket)
    },

    // 设置规则校验hooks参数信息
    checkRulesStoreParams(context, data) {
      context.commit('checkRulesStoreParams', data)
    },

    setSiteInformation(context, data) {
      //设置站点信息
      context.commit('setSiteInformation', data)
    },
    clearState(context, data) {
      context.commit('clearState')
    },
    setActivepath(context, data) {
      context.commit('setActivepath', data)
    },
    setModeData(context, data) {
      context.commit('setModeData', data)
    },
    setModeError(context, data) {
      context.commit('setModeError', data)
    },
    async setMenu(context) {
      const res = await API.getPermissions()
      try {
        // let MenuList = []
        let dataTypeMap = {}
        res.data.msg
          .filter(item => item.type === '数据' || item.type === '操作')
          .forEach(item => {
            dataTypeMap[item.name] = item
          })
        context.commit('setOperatePermissions', dataTypeMap)
        const MenuArray = getMenuList(res.data.msg, deepCopy(routerList))
        context.commit('setMenu', MenuArray)

        // // 账户管理
        // MenuArray[3].children.push({
        //   path: 'AccountInManagement',
        //   name: 'AccountInManagement',
        //   component: () =>
        //     import(
        //       /*webpackChunkName: "AccountInManagement"*/ 'views/system/accountInManagement'
        //     ),
        //   meta: {
        //     _meauname: '账户管理',
        //     keepAlive: true,
        //     icon: "icon-zhandianguanli",
        //     seq: "1"
        //   },
        // });
        // // 角色管理
        // MenuArray[3].children.push({
        //   path: 'RoleManagement',
        //   name: 'RoleManagement',
        //   component: () =>
        //     import(
        //       /*webpackChunkName: "RoleManagement"*/ 'views/system/roleManagement'
        //     ),
        //   meta: {
        //     _meauname: '角色管理',
        //     keepAlive: true,
        //     icon: "icon-zhandianguanli",
        //     seq: "1"
        //   },
        // });

        MenuArray.forEach(element => {
          router.addRoute(element)
        })
        childRouter.forEach(item => {
          const { parentName, ...otherParameters } = item
          router.addRoute(parentName, otherParameters)
        })
      } catch (error) {
        console.log(error)
        // context.commit('clearState')
        // router.push({
        //   path: '/login',
        // })
      }
    },
    async autoLogin(context) {
      const config = getStorage('autoLoginConfig', 'session')
      const { username, password } = config

      const domain_path = window.location.pathname.split('/')[1]
      const res = await API.login({
        user_name: username,
        password: password,
        domain_path,
      })

      if (res.data.code === 200) {
        setStorage('userInfo', res.data.msg)
        router.go(0)
      }
    },
    setParallelSpace(context, data) {
      context.commit('setParallelSpace', data)
    },
    setUserRole(context, data) {
      context.commit('setUserRole', data.user_info.roleids.split(','))
      context.commit('setUserInfo', data)
    },
    setResponseError(context, data) {
      let list = context.state.responseErrList
      if (typeOf(data) === 'array') {
        list = data
      } else {
        list.unshift(data)
      }
      context.commit('setResponseError', list)
    },
    setFunctionDoc(context, data) {
      context.commit('setFunctionDoc', data)
    },
    setShowRelogin(context, data) {
      context.commit('setShowRelogin', data)
    },
    // 获取语言数据
    async fetchLanguage(context) {
      const { data } = await getConfigApi({
        application: 'linku_ems',
        datasource: 'dataset_sql',
        name: 'language_i18n',
        current_flag: 1,
      })
      if (data.code === 200 && data.msg.length > 0) {
        let obj = JSON.parse(data.msg[0]?.content)
        obj = {
          zh: {
            ...obj.zh,
            ...zh,
          },
          en: {
            ...obj.en,
            ...en,
          },
          th: {
            ...obj.th,
            ...th,
          },
        }
        localStorage.setItem('languageData', JSON.stringify(obj))
        context.commit('setLanguage', obj)
      } else {
        ElMessage.warning('多语言数据集获取失败')
      }
    },
  },
})
