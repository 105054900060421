<template>
  <Dialog title="全局配置" width="500px" type="dark" @open="handleOpen">
    <el-divider style="border-color: #555; margin-top: 0"></el-divider>
    <div class="setting-item">
      <div class="setting-label">图表线条</div>
      <div class="setting-value">
        <el-input-number v-model="form.echart.lineWidth" size="small"></el-input-number>
      </div>
      <div class="setting-desc">设置所有图表的线段粗细</div>
    </div>
    <div class="setting-item">
      <div class="setting-label">图表文字</div>
      <div class="setting-value">
        <el-input-number v-model="form.echart.fontSize" size="small"></el-input-number>
      </div>
      <div class="setting-desc">设置所有图表的文字大小</div>
    </div>

    <template #footer>
      <el-button size="small" @click="cancel">取消</el-button>
      <el-button type="primary" size="small" @click="handleSave">保存</el-button>
    </template>
  </Dialog>
</template>

<script setup>
import { ElMessage, ElMessageBox } from 'element-plus'
import { ref } from 'vue'
import Dialog from '../dialog'

const form = ref({
  echart: {
    lineWidth: 1,
    fontSize: 14,
  },
})

const emits = defineEmits(['close'])

const cancel = () => {
  emits('close')
}

const handleSave = () => {
  localStorage.setItem('globalSettings', JSON.stringify(form.value))
  emits('close')
  ElMessageBox({
    title: '操作成功',
    message: '设置成功后需要刷新后生效，是否立即刷新页面？',
    showCancelButton: true,
    confirmButtonText: '立即刷新',
    cancelButtonText: '稍后自行刷新',
    type: 'warning',
  }).then(() => {
    window.location.reload()
  })
}

const handleOpen = () => {
  let settings = localStorage.getItem('globalSettings')
  if (settings) {
    form.value = JSON.parse(settings)
  } else {
    form.value = {
      echart: {
        lineWidth: 1,
        fontSize: 14,
      },
    }
  }
}
</script>

<style scoped lang="scss">
.setting-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  .setting-label {
    color: #fff;
    font-size: 16px;
    margin-right: 10px;
  }
  .setting-value {
    margin-right: 20px;
  }
  .setting-desc {
    color: #aaa;
    font-size: 14px;
  }
}
</style>
