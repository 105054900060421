import { ref, reactive, toRefs, watch, computed } from 'vue'
import API from '@/server/dataset'
import { ElMessage } from 'element-plus'
import { useStore } from 'vuex'

// 参数
const checkRulesParams = reactive({
  dialogVisible: false,
  tableData: [],
})

export default function useCheckRules() {
  const store = useStore()
  // 获取校验规则
  async function handleCheckRules(name, isContinue = '', APIFun, funParams, callback) {
    // 校验
    if (isContinue !== 'goon') {
      store.dispatch('checkRulesStoreParams', { name, isContinue, APIFun, funParams, callback })
    }

    let params = {
      application: 'config',
      datasource: 'page_path',
    }
    let app = {
      application: 'config',
      datasource: 'config_service',
      name: 'all',
    }
    let item = []
    let headersParams
    if (isContinue !== 'goon') {
      const { data: resData } = await API.dataset(params, app)
      if (resData.code === 200) {
        item = resData.msg.filter(i => name === JSON.parse(i.content).routePath)
        if (item.length === 0 && process.env.NODE_ENV === 'development') {
          ElMessage.warning('未找到该界面功能的校验规则')
        }
      } else {
        ElMessage.warning('校验规则获取失败')
      }
    }

    if (item.length !== 0) {
      headersParams = item.map(i => JSON.parse(i.content))
    }
    console.log(headersParams)
    headersParams = isContinue === 'goon' ? undefined : headersParams

    let { data } = await APIFun({ ...funParams, headersParams })
    console.log(data)
    if (isContinue !== 'goon') {
      // data = {
      //   checkParam: 'CheckRule',
      //   '/events/_element[13]/outputData/_element[0]/dataType/type': '=intss 必须在以下范围内:int | float | double | text | enum | bool | array | date | list',
      //   pass: false,
      // }

      // 如果校验未通过就弹窗提示
      if (data.pass === false) {
        let arr = []
        for (const item of data.result) {
          for (const key in item) {
            arr.push({
              fieldPath: key,
              promptInfo: item[key],
            })
          }
        }
        checkRulesParams.tableData = arr
        checkRulesParams.dialogVisible = true
        return Promise.reject(false)
      } else {
        checkRulesParams.dialogVisible = false
        callback(data)
        return data
      }
    } else {
      checkRulesParams.dialogVisible = false
      callback(data)
      return data
    }
  }

  return {
    checkRulesParams,
    handleCheckRules,
  }
}
