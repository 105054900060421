<template>
  <el-button @click="initImport(true)" link type="primary">导入整站</el-button>
  <Dialog title="整站导入" :append-to-body="true" width="500px" v-model:visible="visible">
    <div class="content">
      <div>
        <el-form label-width="130px">
          <el-form-item label="是否导入通讯配置">
            <el-checkbox v-model="importConfig.isImportProtocal"></el-checkbox>
          </el-form-item>
          <el-form-item label="导入内容">
            <el-radio-group v-model="importConfig.importContent" @change="handleImportContentChange">
              <el-radio label="整站数据"></el-radio>
              <el-radio label="仅导入策略"></el-radio>
              <el-radio label="仅导入设备"></el-radio>
              <el-radio label="导入策略和设备"></el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="选择微网" v-if="importConfig.importContent !== '整站数据' && gridOptions.length > 1">
            <el-select v-model="importConfig.chooseGrid" placeholder="选择目标微网">
              <el-option v-for="item in gridOptions" :label="item.label" :value="item.value" :key="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="导入文件">
            <el-button @click="importStation" size="small" :loading="loading">选择导入文件</el-button>
          </el-form-item>
        </el-form>
      </div>

      <el-divider>导入日志</el-divider>
      <div class="logs no-scrollbar" ref="logsRef">
        <el-timeline style="max-width: 600px">
          <el-timeline-item
            v-for="(activity, index) in activities"
            :key="index"
            :icon="activity.icon"
            :type="activity.type"
            :color="activity.color"
            :size="activity.size"
            :hollow="activity.hollow"
            :timestamp="activity.timestamp"
          >
            {{ activity.content }}
          </el-timeline-item>
        </el-timeline>
      </div>
    </div>
    <template #footer>
      <el-button @click="visible = false" :loading="loading">关闭</el-button>
    </template>
  </Dialog>
</template>

<script setup>
import { nextTick, reactive, ref } from 'vue'
import { getDeviceTags, createOrUpdateData, getDevice, getGateway, getController } from '@/server/device'
import API from '@/server/dataset'
import useProductDict from '@/hooks/productDict'
import { getAllDevice } from '@/views/singleSiteMonitor/policyManagement/jsonLogic/jsonLogicMethods'
import { insertData } from '@/views/singleSiteMonitor/siteSettings/deviceManagement/components/exportAndImport'
import { domainid, typeOf } from '@/utils/utils'
import { ElMessage } from 'element-plus'
import Dialog from '@/components/dialog'
import { format } from 'date-fns'

const props = defineProps({
  siteId: String,
  siteName: String,
  extra_content: String,
  aiData: String,
})

/** 导出 */
// 1. 获取tag分组，作为整体架构
// 2. 获取tag分组下的所有设备，控制器，并插入到对应分组中，再将其转化为导出内容格式
// 3. 替换站点/微网/单元分组内存储的配置信息的设备/控制器名称
//   a. 空调系统图(微网)
//   b. 首页配置(站点)
//   c. 拓扑图面板(微网/单元)
//   d. 所有面板配置(站点/微网/单元)
// 4. 查询所有的微网调试tag，并替换其中的设备/控制器内容

// 设备/控制器/tag的id/iot_id/devicename等对照表
const dictMap = new Map()
let produckeys = []
let gateways = []

let updateData = []
const visible = ref(false)
const importConfig = reactive({
  isImportProtocal: true,
  importContent: '整站数据',
  chooseGrid: '',
})
const gridOptions = ref([])
const loading = ref(false)
const logsRef = ref()
const activities = ref([
  {
    content: 'Custom icon',
    timestamp: '2018-04-12 20:46',
    type: 'primary',
  },
  {
    content: 'Custom color',
    timestamp: '2018-04-03 20:46',
    color: '#0bbd87',
  },
  {
    content: 'Custom size',
    timestamp: '2018-04-03 20:46',
  },
  {
    content: 'Custom hollow',
    timestamp: '2018-04-03 20:46',
    type: 'primary',
  },
  {
    content: 'Default node',
    timestamp: '2018-04-03 20:46',
  },
])

const handleImportContentChange = async val => {
  if (val !== '整站数据' && gridOptions.value.length === 0) {
    const res = await getDeviceTags({
      recursive: true,
      root_category: 'energy_subsystem',
      root_id: props.siteId,
    })
    gridOptions.value = res.data.msg[0].children.map(item => {
      return {
        label: item.item_label,
        value: item.id,
      }
    })
  }
}

let keys = [
  'item_device_no',
  'item_energy_meter',
  'item_extra_content',
  'item_group_id',
  'item_iot_categoryname',
  'item_iot_general_config',
  'item_iot_productname',
  'item_name',
  'item_iot_config',
  'item_subdevice_no',
]

// 过滤不需要的字段数据
const filterRequiredKeys = (list, entity_type) => {
  return list.map(item => {
    let obj = {
      entity_type,
    }
    keys.forEach(key => {
      if (key === 'item_iot_general_config') {
        item[key] = item[key] || {}
        item[key].debugMode = false
      }
      if (typeOf(item[key]) === 'object') {
        obj[key] = JSON.stringify(item[key])
      } else {
        obj[key] = item[key]
      }
    })
    const findProduct = produckeys.find(val => val.label === item.item_iot_productname)
    obj.item_iot_productkey = (findProduct || {}).value
    obj.item_iot_gateway = gateways[0].item_iot_id

    if (item.children && item.children.length) obj.children = filterRequiredKeys(item.children, entity_type)

    // 不导入通讯协议配置
    if (obj.entity_type === 'device' && !importConfig.isImportProtocal) {
      delete obj.item_iot_config
    }
    return obj
  })
}

// 创建站点tag
const createTag = async (data, parent_id) => {
  try {
    let old_id = data.id
    let new_id = ''
    // 移除不需要更新/存储的字段
    if (data.item_subcategory === '站点') {
      let extra_content_keys = [
        'site_address',
        'start_time',
        'power',
        'volume',
        'homepage_title',
        'homepage_subtitle',
        'site_type',
        'allow_overdraft',
        'access_role',
        'hide_charging_station',
      ]
      let old_extra_content = JSON.parse(props.extra_content)
      extra_content_keys.forEach(key => {
        data.item_extra_content[key] = old_extra_content[key]
      })
      parent_id = ''
      data.id = props.siteId
      data.item_label = props.siteName
    } else {
      delete data.id
    }
    let params = {
      id: data.id,
      entity_type: 'tag',
      item_category: data.item_category,
      item_subcategory: data.item_subcategory,
      item_label: data.item_label,
      item_extra_content: JSON.stringify(data.item_extra_content || ''),
    }
    // 创建/更改tag
    let res = await createOrUpdateData({ tag_id: parent_id, data: [params] })
    if (res.data.msg) {
      new_id = res.data.msg[0].id
      dictMap.set(old_id, new_id)
      updateData.push({
        id: new_id,
        entity_type: 'tag',
        item_extra_content: JSON.stringify(data.item_extra_content || ''),
      })
      insertLog({
        content: params.item_subcategory === '站点' ? `更新${data.item_label}站点信息成功` : `创建${data.item_label}分组成功`,
      })
    }
    // 创建该tag下设备
    if (data.devices) {
      await createDevice(data.devices, new_id, data.item_label)
    }
    // 创建该tag下控制器
    if (data.controllers) {
      await createController(data.controllers, new_id, data.item_label)
    }
    if (data.children && data.children.length) {
      await Promise.all(
        data.children.map(item => {
          return createTag(item, new_id)
        }),
      )
    }
  } catch (error) {
    setErrorLog(error)
  }
}

const createController = async (list, tag_id, label) => {
  await createOrUpdateData({
    tag_id: tag_id,
    data: filterRequiredKeys(list, 'controller'),
    tag: tag_id,
  })
  insertLog({
    content: `创建${label}分组下策略/集群/模拟器/费率成功`,
  })
}
const createDevice = async (list, tag_id, label) => {
  let deviceList = filterRequiredKeys(list, 'device')
  await insertData(deviceList, tag_id, '', '设备', props.siteId)
  insertLog({
    content: `创建${label}分组下设备成功`,
  })
}

let importData = {}
// 导入整站
const importStation = async () => {
  initImport()
  try {
    // 先查询该站点下有没有网关
    const gatewayRes = await getGateway({
      tag: 'energy_subsystem',
      value: props.siteId,
      include_details: 1,
    })
    if (gatewayRes.data.msg.length === 0) {
      throw '该站点下没有网关，请先添加网关'
    }
    gateways = gatewayRes.data.msg
    // 查询设备型号
    produckeys = await useProductDict()

    if (props.aiData) {
      importData = JSON.parse(props.aiData)
      startImportData(importData)
    } else {
      // 导入数据
      const file_input = document.createElement('input')
      file_input.type = 'file'
      file_input.addEventListener('change', e => {
        if (e.target.files.length) {
          const reader = new FileReader()
          const file = e.target.files[0]
          file.accept = '.json'
          reader.readAsText(file, 'utf-8')
          reader.onload = async () => {
            let result = JSON.parse(reader.result)
            importData = result
            startImportData(result)
          }
        }
      })
      file_input.click()
    }
  } catch (error) {
    console.log(error)
    setErrorLog(error)
  }
}

const startImportData = async result => {
  if (result.type !== '整站数据') {
    throw '导入文件内容错误'
  } else {
    loading.value = true
    if (importConfig.importContent === '整站数据') {
      // 创建站点tag
      await createTag(result.station)
      // 创建微网调试tags
      const chartTags = result.chartGroups.data.map(item => {
        return {
          item_label: item.item_label,
          item_extra_content: JSON.stringify(item.item_extra_content || ''),
          entity_type: 'tag',
          item_category: '微网调试',
          item_subcategory: '调试',
        }
      })
      const res = await createOrUpdateData({ tag_id: props.siteId, data: chartTags })
      if (res.data.code === 200) {
        res.data.msg.forEach((item, index) => {
          dictMap.set(result.chartGroups.data[index].id, item.id)
          updateData.push({
            id: item.id,
            entity_type: 'tag',
            item_extra_content: item.item_extra_content,
          })
        })
        insertLog({
          content: `创建了${res.data.msg.length}个微网调试`,
        })
      } else {
        throw '创建微网调试失败'
      }
      // 生成对照字典
      insertLog({
        content: `等待设备上传iot平台`,
        type: 'info',
      })
    } else if (importConfig.importContent === '仅导入策略' || importConfig.importContent === '仅导入设备' || importConfig.importContent === '导入策略和设备') {
      let grid_id = importConfig.chooseGrid || gridOptions.value[0].value
      let grid_label = gridOptions.value.find(item => item.value === grid_id).label
      let allData = {
        controllers: [],
        devices: [],
      }
      const getAllTarget = (data, key) => {
        if (data[key] && data[key].length) {
          allData[key] = allData[key].concat(data[key])
        }
        if (data.children && data.children.length) {
          data.children.forEach(item => {
            getAllTarget(item, key)
          })
        }
      }
      if (importConfig.importContent === '仅导入策略' || importConfig.importContent === '导入策略和设备') {
        getAllTarget(result.station, 'controllers')
        // 创建策略
        await createController(allData.controllers, grid_id, grid_label)
      }
      if (importConfig.importContent === '仅导入设备' || importConfig.importContent === '导入策略和设备') {
        getAllTarget(result.station, 'devices')
        // 创建设备
        await createDevice(allData.devices, grid_id, grid_label)
      }
      loading.value = false
    }
    setTimeout(async () => {
      insertLog({
        content: `开始替换设备/控制器/分组引用的内容`,
        type: 'info',
      })
      if (!props.aiData) {
        await replaceLinkKey()
      }
      insertLog({
        content: '已成功完成导入任务',
        hollow: false,
      })
      loading.value = false
    }, 5000)
  }
}

const setErrorLog = error => {
  console.log(error)
  loading.value = false
  insertLog({
    content: error,
    type: 'danger',
    hollow: false,
  })
}

let allDevices = []
const setDeviceDict = (item, key) => {
  const newDevice = allDevices.find(device => device.label === item.item_name)
  if (newDevice) {
    dictMap.set(item.id, newDevice.id)
    dictMap.set(item.item_iot_id, newDevice.iot_id)
    dictMap.set(`${item.item_iot_productkey}#${item.item_iot_devicename}`, newDevice.value)
    updateData.push({
      id: newDevice.id,
      entity_type: key === 'devices' ? 'device' : 'controller',
      item_extra_content: newDevice.extra_content,
      item_iot_general_config: newDevice.general_config,
    })
  }
}
const replaceLinkKey = async () => {
  try {
    // 获取所有新导入的设备
    allDevices = await getAllDevice({
      parent_id: props.siteId,
      gateway: props.siteId,
    })
    // 递归遍历旧设备/控制器，获取他们的id,iot_id,devicename#productkey放入字典
    const getKeys = obj => {
      if (obj.item_label) {
        let keys = ['devices', 'controllers']
        if (importConfig.importContent === '仅导入策略') {
          keys = ['controllers']
        } else if (importConfig.importContent === '仅导入设备') {
          keys = ['devices']
        }
        for (let key of keys) {
          if (obj[key]) {
            for (let item of obj[key]) {
              setDeviceDict(item, key)
            }
          }
        }
      } else {
        setDeviceDict(obj, 'devices')
      }

      if (obj.children && obj.children.length) {
        obj.children.forEach(item => {
          getKeys(item)
        })
      }
    }
    getKeys(importData.station)

    function replaceKeywords(inputString) {
      // 构建正则表达式匹配所有关键词
      var regexPattern = new RegExp([...dictMap.keys()].join('|'), 'g')

      // 替换字符串中的关键词
      var replacedString = inputString.replace(regexPattern, function (match) {
        return dictMap.get(match)
      })

      return replacedString
    }
    // 更新所有设备
    updateData = updateData.map(item => {
      if (item.item_extra_content) {
        item.item_extra_content = replaceKeywords(item.item_extra_content)
      }
      if (item.item_iot_general_config) {
        item.item_iot_general_config = replaceKeywords(item.item_iot_general_config)
      }
      return item
    })
    insertLog({
      content: '替换成功，正在更新',
    })
    await createOrUpdateData({ tag_id: '', data: updateData })
    insertLog({
      content: '更新成功',
    })
  } catch (error) {
    setErrorLog(error)
  }
}

const insertLog = params => {
  const { content, type = 'primary', hollow = true } = params
  activities.value.push({
    content,
    timestamp: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
    type,
    hollow,
  })
  nextTick(() => {
    logsRef.value.scrollTop = logsRef.value.scrollHeight + 64
  })
}

const initImport = isFirst => {
  dictMap.clear()
  produckeys = []
  gateways = []
  updateData = []
  importData = {}
  allDevices = []
  activities.value = []
  visible.value = true
  if (isFirst) {
    importConfig.importContent = '整站数据'
    importConfig.isImportProtocal = true
    importConfig.chooseGrid = ''
    gridOptions.value = []
  }
}

const createStationByAi = async () => {
  initImport(true)
  importConfig.importContent = '仅导入设备'
  await handleImportContentChange()
  importStation()
}

defineExpose({
  createStationByAi,
})

/** 导入
 * 1. 先导入站点tag，导入站点下的控制器/设备
 * 2. 导入微网tag，导入微网下的控制器/设备
 * 3. 导入单元tag，导入单元下的控制器/设备
 * 6. 导入所有的微网调试
 * 4. 查询所有设备/控制器同步生成一个map字典
 *    {
 * 		"设备/控制器id": "导入后的id",
 * 		"设备/控制器iot_id": "导入后的iot_id",
 *      "设备/控制器devicename#productkey": "导入后的devicename#productkey",
 *      "站点/微网/单元id": "导入后的id",
 *    }
 * 5. 所有tag生成json，替换全部在map中的内容
 */
</script>

<style scoped lang="scss">
.content {
  height: 100%;
  display: flex;
  flex-direction: column;
  .logs {
    padding: 0 10px;
    flex: 1;
    overflow-y: scroll;
  }
}
</style>
