import axios from '../utils/aioxs'
import { domainid } from '@/utils/utils'
import Jons from 'utils/dataset.json'

export function getConfigApi(data) {
  let params = {
    application: 'config',
    datasource: 'config_service',
    name: 'all',
    param: {
      ...data,
    },
    query: Jons.config.config_service.all,
  }
  return axios({
    url: '/datapool/mysql?format=row',
    method: 'post',
    data: params,
  })
}

export function getProductModelListApi(data) {
  let params = {
    application: 'config',
    datasource: 'config_service',
    name: 'iotproduct',
    param: {
      ...data,
    },
    query: Jons.config.config_service.iotproduct,
  }
  return axios({
    url: '/datapool/mysql?format=row',
    method: 'post',
    data: params,
  })
}

// 保存配置
export function updateConfigApi(data) {
  // const { app_application, app_datasource, app_name, ...attrs } = data || {}
  // let params = {
  //   [app_application]: {
  //     [app_datasource]: {
  //       [app_name]: attrs,
  //     },
  //   },
  // }
  return axios({
    url: `/datapool/query_config/import`,
    method: 'post',
    data: data,
  })
}

// 删除应用配置
export function deleteConfigApi(data) {
  return axios({
    method: 'post',
    url: `/datapool/mysql/${domainid()}/config_service/sql_config`,
    data,
  })
}
