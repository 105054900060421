import axios from '../utils/aioxs'
import { domainid } from '@/utils/utils'

// 获取设备分组标签
export function getDeviceTags(params) {
  return axios({
    method: 'get',
    url: `/operation/${domainid()}/tag`,
    params,
  })
}

// 创建/更新数据
export function createOrUpdateData({ tag_id, data, tag, headersParams = undefined }) {
  let headers = {}
  if (headersParams !== undefined) {
    headers = {
      CheckRule: encodeURIComponent(JSON.stringify(headersParams)),
    }
  } else {
    headers = {}
  }

  let url = `/operation/${domainid()}/batch`
  if (tag) {
    url += `?tag_dev_id=${tag}`
    if (tag_id) {
      url += `&tag_id=${tag_id}`
    }
  } else if (tag_id) {
    url += `?tag_id=${tag_id}`
  }
  return axios({
    method: 'post',
    url,
    data,
    headers,
  })
}

// 提交设备属性设置
export function setDeviceControl({ data, headersParams = undefined }) {
  let headers = {}
  if (headersParams !== undefined) {
    headers = {
      CheckRule: encodeURIComponent(JSON.stringify(headersParams)),
    }
  } else {
    headers = {}
  }

  return axios({
    method: 'post',
    url: `/iot_dev/control`,
    data,
    headers,
  })
}

// 删除数据
export function deleteData(data, type) {
  return axios({
    method: 'delete',
    url: `/operation/${domainid()}${type || ''}/batch`,
    data,
  })
}

// 查询设备
export function getDevice(params) {
  return axios({
    method: 'get',
    url: `/operation/${domainid()}/device`,
    params,
  })
}

// 查询网关
export function getGateway(params) {
  return axios({
    method: 'get',
    url: `/operation/${domainid()}/gateway`,
    params,
  })
}

// 查询控制器
export function getController(params) {
  return axios({
    method: 'get',
    url: `/operation/${domainid()}/controller`,
    params,
  })
}

// 获取产品类别字典
export function getCategoryDict(params) {
  return axios({
    method: 'get',
    url: '/thing_model/category/list',
    params,
  })
}

// 获取型号字典
export function getProductDict(params) {
  return axios({
    method: 'get',
    url: '/thing_model',
    params,
  })
}

// 获取设备参数字典
export async function getPropertyDict(iot_id) {
  if (!iot_id) return
  return axios({
    method: 'get',
    url: `/thing_model/device/${iot_id}/property_dict`,
  })
}

// 通过设备类型获取设备参数字典
export function getPropertyDictByProduct(product_key) {
  return axios({
    method: 'get',
    url: `/thing_model/product/${product_key}/property_dict`,
  })
}

// 通过category获取设备参数字典
export function getPropertyDictByCategory(category) {
  return axios({
    method: 'get',
    url: `/thing_model/category/${category}/property_dict`,
  })
}

// 获取用电类型字典
export function getEnergyTypeDict(params) {
  return axios({
    method: 'get',
    url: `/operation/${domainid()}/energy/energy_type_dict`,
    params,
  })
}

// 获取电量统计
// 获取用电类型字典
export function getEnergyStatistics(params) {
  return axios({
    method: 'get',
    url: `/operation/${domainid()}/energy`,
    params,
  })
}

// 插叙设备统计值
export function getDeviceStatistic(params) {
  return axios({
    method: 'get',
    url: `/operation/${domainid()}/device/statistic`,
    params,
  })
}

// 获取告警明细
export function getAlarmListAPI(params) {
  return axios({
    method: 'get',
    url: `/operation/${domainid()}/alert/list`,
    params,
  })
}

// 获取告警汇总
export function getAlarmSummaryAPI(params) {
  return axios({
    method: 'get',
    url: `/operation/${domainid()}/alert/summary`,
    params,
  })
}

// 获取设备最后告警状态
export function getAlarmLatestState(params) {
  return axios({
    method: 'get',
    url: `/operation/${domainid()}/alert/latest`,
    params,
  })
}

// 获取设备最后告警状态汇总
export function getAlarmLatestStateSummary(params) {
  return axios({
    method: 'get',
    url: `/operation/${domainid()}/alert/latest/summary`,
    params,
  })
}

// 查询计量设备
export function getEnergyMeter(params) {
  return axios({
    method: 'get',
    url: `/operation/${domainid()}/energy_meter`,
    params,
  })
}

// 创建更新充电桩
export function createOrUpdatePile({ tag_id, data, tag, headersParams = undefined }) {
  let headers = {}
  if (headersParams !== undefined) {
    headers = {
      CheckRule: encodeURIComponent(JSON.stringify(headersParams)),
    }
  } else {
    headers = {}
  }

  let url = `/operation/${domainid()}/evcp/batch`
  if (tag_id) {
    url += `?tag_id=${tag_id}`
    if (tag) {
      url += `&tag_dev_id=${tag}`
    }
  } else if (tag) {
    url += `?tag_dev_id=${tag}`
  }
  return axios({
    method: 'post',
    url,
    data,
    headers,
  })
}
// 创建更新充电枪
export function createOrUpdateGun({ tag_id, data, tag, headersParams = undefined }) {
  let headers = {}
  if (headersParams !== undefined) {
    headers = {
      CheckRule: encodeURIComponent(JSON.stringify(headersParams)),
    }
  } else {
    headers = {}
  }

  let url = `/operation/${domainid()}/evcs/batch`
  if (tag_id) {
    url += `?tag_id=${tag_id}`
    if (tag) {
      url += `&tag_dev_id=${tag}`
    }
  } else if (tag) {
    url += `?tag_dev_id=${tag}`
  }
  return axios({
    method: 'post',
    url,
    data,
    headers,
  })
}

// 充电桩启用/禁用
export function pileEnableOrDisable(iot_id, type) {
  let url = `/operation/${domainid()}/evcp/${iot_id}/${type}`
  return axios({
    method: 'post',
    url,
  })
}

// 充电枪生成二维码
export function evcsQrcode(iot_id) {
  let url = `/operation/${domainid()}/evcs/${iot_id}/qrcode`
  return axios({
    method: 'post',
    url,
  })
}

// 获取第三方小程序列表
export function getChargeApp() {
  return axios({
    method: 'get',
    url: `/operation/${domainid()}/evcs/dict/charge_app`,
  })
}

// 获取设备导出信息
export function getDevicesExportInfo(params) {
  return axios({
    method: 'get',
    url: `/iot_dev/${domainid()}/device/list/qrcode`,
    params,
  })
}

// 查询设备qrcode
export function getDeviceQrcode(iot_id) {
  return axios({
    method: 'get',
    url: `/iot_dev/${domainid()}/device/${iot_id}/qrcode`,
  })
}

// 设备启用/禁用
export function deviceEnableOrDisable(iot_id, type, entity_type = 'device') {
  let url = `/iot_dev/${domainid()}/${entity_type}/${iot_id}/iot_${type}`
  return axios({
    method: 'post',
    url,
  })
}

// 批量设备设置
export function batchDeviceSet(data, entity_type, action) {
  return axios({
    method: 'post',
    url: `/iot_dev/${domainid()}/${entity_type}/list/${action}`,
    data,
  })
}

const getEvcsPrevUrl = () => {
  const mode = process.env.NODE_ENV
  if (mode === 'development') return '/'
  return ''
}

// 充电系统状态查询
export function getChargingSystemStatus(params) {
  return axios({
    method: 'get',
    url: `${getEvcsPrevUrl()}/evcs/${domainid()}/status`,
    params,
  })
}

// 充电系统充电车次/时长查询
export function getChargingSystemSummary(params) {
  return axios({
    method: 'get',
    url: `${getEvcsPrevUrl()}/evcs/${domainid()}/summary`,
    params,
  })
}

// 充电系统车辆SOC
export function getSoc(params) {
  return axios({
    method: 'get',
    url: `${getEvcsPrevUrl()}/evcs/${domainid()}/soc`,
    params,
  })
}

// 电量时段查询
export function getEnergyTimes(params) {
  return axios({
    method: 'get',
    url: `/operation/${domainid()}/period/energy`,
    params,
  })
}

// 获取月份时段电价
export function getMonthEnergyPrice(params) {
  return axios({
    method: 'get',
    url: `/operation/${domainid()}/month/price`,
    params,
  })
}

// 设备累加数据查询
export function getMeasurementIntegration(params) {
  return axios({
    method: 'get',
    url: `/operation/${domainid()}/measurement/integration`,
    params,
  })
}
// 充电枪强制停止正在进行的订单
export function cancelEvcsOrder(iot_id, params) {
  return axios({
    method: 'post',
    url: `/operation/${domainid()}/evcs/${iot_id}/canceltrade`,
    data: params,
  })
}
// 获取存在active_power的设备
export function getPowerDevice(params) {
  return axios({
    method: 'get',
    url: `/operation/${domainid()}/product/list/power_device`,
    params,
  })
}

// 获取单设备属性历史原始数据
export function getDeviceChannelTimeData(data) {
  let url = window.location.origin
  let ems = 'linku_ems_prod'
  if (url.includes('emsdev') || !url.includes('ems')) {
    ems = 'linku_ems'
  }
  return axios({
    method: 'post',
    url: `/tsdb/${ems}?format=dict`,
    data: {
      sql: `select time, ${data.channel} from ${data.table} where domain_id='${domainid()}' and iot_id='${data.iot_id}' and time >= '${
        data.start_time
      }' and time <= '${data.end_time}' order by time ${data.order || 'asc'}`,
    },
  })
}

// 获取sql数据
export function getSqlData(params, format) {
  let url = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_PROXY_URL : process.env.VUE_APP_SERVICE_URL
  let ems = 'linku_ems_prod'
  if (url.includes('servicedev')) {
    ems = 'linku_ems'
  }
  return axios({
    method: 'get',
    url: `/tsdb/${ems}?format=${format}`,
    params,
  })
}

// 获取唯一序列号
export function getSeriaNumber(params) {
  return axios({
    method: 'get',
    url: `/system_config/serial_number`,
    params,
  })
}

// 获取快照列表
export function getSnapshot(params) {
  return axios({
    method: 'post',
    url: `/datapool/mysql`,
    data: {
      name: '策略快照',
      application: 'linku_ems',
      datasource: 'ems_backend',
      param: {
        domain_id: domainid(),
        ...params,
      },
      query: {
        sql: `select operation_id, create_time ${
          params.operation_id ? ',raw' : ''
        } from t_log where domain_id='####domain_id####' and iot_id='####iot_id####' and (ifnull(####operation_id####, '')='' or operation_id='####operation_id####') and (ifnull(####begin####, '')='' or create_time >= '####begin####') and (ifnull(####end####, '')='' or create_time <= '####end####')`,
        param: {
          domain_id: 'required',
          begin: 'optional',
          end: 'optional',
          operation_id: 'optional',
          iot_id: 'optional',
        },
        desc: '',
      },
    },
  })
}

// 查询设备调试物模型
export function getDebugThingModelApi(params) {
  return axios({
    method: 'get',
    url: `/thing_model/debug`,
    params,
  })
}
// 更新设备调试物模型
export function updateDebugThingModelApi(iot_id, data) {
  return axios({
    method: 'post',
    url: `/thing_model/debug?iot_id=${iot_id}`,
    data,
  })
}
// 推送配置信息
export function pushToIotApi(data) {
  return axios({
    method: 'post',
    url: `/iot_dev/${domainid()}/device/${data.iot_id}/push_to_iot`,
    data,
  })
}

// // 配置表查询
// export function getConfigApi(data) {
//   data.param.current_flag = 1
//   return axios({
//     method: 'post',
//     url: `/datapool/mysql`,
//     data: {
//       ...data,
//     },
//   })
// }

// ai模型定义
export function setLoadForecastModelApi(data) {
  return axios({
    method: 'post',
    url: `/ai/load_forecast/model`,
    data,
  })
}
// ai预测查询
export function getLoadForecastApi(data) {
  return axios({
    method: 'post',
    url: `/ai/load_forecast/forecast`,
    data,
  })
}

// 生成短码
export function createShortUrlApi(data) {
  return axios({
    method: 'post',
    url: `/shorturl`,
    data,
  })
}

// 获取短码信息
export function getShortUrlApi(data) {
  return axios({
    method: 'get',
    url: `/shorturl`,
    params: data,
  })
}
